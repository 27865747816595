<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="maincard">
				<Toast/>
				<!--Toolbar class="p-mb-0">
					<template slot="left"-->
						<!--Button label="Új" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" /-->
						<!--Button label="Töröl" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedVisits || !selectedVisits.length" /-->
						<!--Button label="Teszt" icon="pi pi-plus" class="p-button-info p-mr-2" @click="teszt" style="margin-left: 7px" /-->
                        <!--Dropdown v-model="visitClass" :options="codesVisitClass" optionLabel="value" optionValue="code" placeholder="Select a Class" /-->
					<!--/template-->

					<!--template slot="right">
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-d-inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template-->
				<!--/Toolbar-->


                <!-- Táblázat -->
				<DataTable ref="dt" :value="visits" dataKey="id" :paginator="true" :rows="25"
                            sortField="date" :sortOrder="-1" :loading="loading" 
                            csvSeparator=";" :exportFilename="exportFilenameVisits"
                            v-model:filters="filters" :globalFilterFields="['date','homecarer','patientname','req_reason_val']"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,100]"
							currentPageReportTemplate="{first} - {last} / {totalRecords}" class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows ">
					<template #header>
						<div class="table-header p-grid p-justify-between">
                            <div>
                                <Button :icon="(loading) ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'" class="p-button-raised p-button-sm p-mr-3" @click="setDefaultAndLoad()" 
                                        v-tooltip.right="'Szűrők törlése és frissítés'"/>
                                <h5 class="p-m-0 p-d-inline-block">Vizit kérelmek jóváhagyása</h5>
                            </div>
                            <div>
                                <div class="p-d-inline-block">
                                    <Calendar id="yearmonth" name="x-visitrequest-datum" v-model.lazy="yearmonth" view="month" dateFormat="yy.mm" 
                                                style="width: 7rem" autocomplete="off"
                                                inputClass="p-d-inline-block"
                                                :yearNavigator="true" yearRange="2000:2030"
                                                @change="loadData()" @date-select="loadData()" />
                                    <div class="p-d-inline-block" style="margin-left: 1.5rem; margin-right: 1.5rem">
                                        <InputSwitch id="showCompletedSwitch" v-model="showCompleted" @change="loadData()"/>
                                        <label for="showCompletedSwitch">Teljesítettek is</label>
                                    </div>                                    
                                </div>
                                <div class="p-d-inline">
                                    <span class="p-input-icon-left p-d-inline-block">
                                        <i :class="(loadingSearch) ? ['pi', 'pi-spin', 'pi-spinner'] : ['pi', 'pi-search']"/>
                                        <InputText v-model="searchGlobal" placeholder="Keresés..." />
                                    </span>
                                    <div class="p-d-inline" style="margin-left: 1.5rem">
                                        <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
                                    </div>                                    
                                </div>
                            </div>
						</div>
					</template>

                    <Column field="date" header="Dátum" sortable headerStyle="width: 6.5rem">
                    </Column>
                    <Column field="homecarer" header="Otthonápoló" sortable headerStyle="width: 12%" v-if="isAdmin">
                    </Column>
                    <Column field="patientname" header="Beteg" sortable headerStyle="width: 12%">
						<template #body="slotProps">
                            <router-link :to="'/beteg/' + slotProps.data.patientid" style="white-space: pre-wrap">
                                {{ slotProps.data.patientname }}
                            </router-link>
                        </template>
                    </Column>
                    <Column field="req_reason_val" header="Kérelem oka" sortable headerStyle="width: 15%">
                    </Column>
					<Column field="req_note" header="Megjegyzés">
						<template #body="slotProps">
                            <div class="p-col">
                                <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.req_note"></p>
                            </div>
                        </template>
                    </Column>
                    <Column field="visit_status_val" header="Állapot" :hidden="true">
                    </Column>
					<Column headerStyle="width: 17rem" header="Művelet" :exportable="false" v-if="isAdmin">
						<template #body="slotProps">
                            <Button :label="(slotProps.data.visit_status=='T') ? 'Teljesítve' : 'Jóváhagyva'"
                                    :class="(slotProps.data.visit_status=='T') ? 'p-button-info' : (slotProps.data.visit_status=='J') ? 'p-button-success' : 'p-button-outlined'"
                                    @click="setStatus(slotProps.data, 'J')"
                                    style="width: 100px"
                                    :style="(slotProps.data.visit_status=='T') ? 'cursor:default' : ''"
                            ></Button>
							<Button label="Elutasítva"
                                    :class="(slotProps.data.visit_status=='X') ? 'p-button-danger' : 'p-button-outlined'"
                                    @click="setStatus(slotProps.data, 'X')"
                                    :disabled="slotProps.data.visit_status=='T'"
                            ></Button>
						</template>
					</Column>
					<Column headerStyle="width: 17rem" header="Státusz" :exportable="false" v-if="!isAdmin">
						<template #body="slotProps">
                            <Badge :value="statusValue(slotProps.data.visit_status)" :class="'visit-status-'+slotProps.data.visit_status"/>
                            <!--span v-html="statusValue(slotProps.data.visit_status)"
                                  :class="(slotProps.data.visit_status=='X') ? 'p-invalid' : 'status-text-' + slotProps.data.visit_status"
                                  style="width: 100px"
                            ></span-->
						</template>
					</Column>
                </DataTable>
			</div>
		</div>
	</div>

</template>

<script>
//import { mapGetters, mapActions } from 'vuex'
import VisitService from '@/service/backend/visit.service'
import {RouterLink} from 'vue-router'
import { store } from '@/store';

export default {
    props:{ ...RouterLink.props },
	data() {
        const visitStatusOptions = [{'code':'K', 'value':'Kérelmezve'}, {'code':'J', 'value':'Jóváhagyva'}, {'code':'X', 'value':'Elutasítva'}, {'code':'T', 'value':'Teljesítve'}]
        const reqReasonOptions = {  '1': 'Nasogastricus szonda csere', 
                                    '2': 'Gastrotubus csere',
                                    '3': 'Button csere',
                                    '4': 'PEG alkatrész csere',
                                    '5': 'Beteg orvosa kéri a vizitet',
                                    '9': 'egyéb' }

        return {

            User: store.getters['user/User'],

			visits: null,
			visitDialog: false,
			deleteVisitDialog: false,
            filters: {
                'global': {value: null},
            },
            submitted: false,
            searchGlobal: "",
            loading: true,
            loadingSearch: false,
            searchInterval: null,

            visitStatusOptions,
            reqReasonOptions,
            yearmonth: null,
            showCompleted: false

		}
	},
    visitService: null,
	created() {
		this.visitService = new VisitService()
        this.showCompleted = localStorage.visitrequests_completed === "true" ? true : false
        this.yearmonth = (localStorage.visitrequests_yearmonth != undefined && localStorage.visitrequests_yearmonth != null) ? localStorage.visitrequests_yearmonth : this.$dayjs().format('YYYY.MM')
    },
    beforeMount() {
        //this.loadCodeStore('visitClass') // Abban az esetben, ha API-s a codeStore - de nem kell ezt tudnia, jöhetne
    },
	mounted() {
        this.loadData();
	},
	methods: {
/*        ...mapActions({
            getCodeStore: 'codeStore/loadCodeStore'
        }), */
        setFiltersDefault(){
            this.showCompleted = false
            this.yearmonth = this.$dayjs().format('YYYY.MM')
            this.searchGlobal = ""
        },
        setDefaultAndLoad() {
            const needReload = this.searchGlobal == ""
            this.setFiltersDefault()
            if(needReload) this.loadData() 
        },
        loadData() {
            this.loading = true

            let yearmonth = ""
            if(this.$dayjs(this.yearmonth).isValid()) 
                if(typeof(this.yearmonth) === 'object')
                     yearmonth = this.$dayjs(this.yearmonth).format('YYYY.MM')
                else yearmonth = this.yearmonth 
            else yearmonth = ""

            this.visitService.getVisitRequests({yearmonth, completed: this.showCompleted})
            .then( result => {
                this.visits = result
                this.initReqReasonVal()
                this.loading = false
            })
        },
        onFilter() {
            this.loadingSearch = false
        },

		exportCSV() {
            this.visits.forEach(v => { v.visit_status_val = this.visitStatusOptions.find(e => e.code == v.visit_status).value })
			this.$refs.dt.exportCSV()
		},
        filterIgenNem(value, filter) {
            if(filter)
                return (value == 'Igen') == filter
            else
                return true
        },
        setStatus(data, new_status){

            if(data.visit_status == 'T') return

            const old_status = data.visit_status // optimisták vagyunk, előre beállítjuk, de ha baj van, vissza
            data.visit_status = new_status
            this.visitService.setVisitRequestStatus({id: data.id, new_status})
            .catch( error => {
                data.visit_status = old_status
                console.log(error)
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            })
        },
        statusValue(val){
            let ret = ""
            switch(val){
                case 'J': ret = "Jóváhagyott"; break;
                case 'X': ret = "Elutasított"; break;
                case 'K': ret = "Kérelmezett"; break;
                default : ret = "Teljesített"
            }
            return ret
        },
        initReqReasonVal(){
            this.visits.forEach(v => {
                v.req_reason_val = this.reqReasonOptions[v.req_reason]
            })
        },
        notEmpty(val){
            // TODO ez is lehetne valami mixin, sok helyen kéne használni
            return val!==null && val!=='' && val!==undefined && (val.length==undefined || val.length>0)
        }
    }, 
    computed: { //todo del
        isAdmin: function(){
            return this.User.role == "ADMIN"
        },
        codesNumilStatusOptions() {
            let a = []
            this.codesNumilStatus.forEach(e => {
                a.push(e.name)
            })
            return a
        },
        exportFilenameVisits: function(){
            return "Vizitkerelmek_" + this.$dayjs().format('YYYY.MM.DD')
        }
    },
    watch: {
        searchGlobal(val) {
            if(val == ""){
                this.filters['global'].value = ""
                this.yearmonth = this.$dayjs().format('YYYY.MM')
                this.loadData()
                return
            } 
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.loadingSearch = true
                setTimeout(() => { 
                    this.filters['global'].value = val; 
                    this.showCompleted = true
                    this.yearmonth = null
                    this.loadData(); 
                    this.onFilter(); 
                }, 20)
            }, 600)
        },
        showCompleted(v) {
            localStorage.visitrequests_completed = v
        },
        yearmonth(v){
            if(v != null && v != "")
                if(v.length==7)
                    localStorage.visitrequests_yearmonth = v
                else
                    localStorage.visitrequests_yearmonth = this.$dayjs(v).format('YYYY.MM')
            else
                delete localStorage.visitrequests_yearmonth
        }
    }
}
</script>
<style>
.p-datatable-tbody,
.p-datatable-row-expansion {
    cursor: auto;
}
.p-card .p-card-content {
    padding: 0 0 0.2rem 0;
}
</style>

<style scoped lang="postcss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.visit-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .visit-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.visit-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

td button {
    margin-right: 1rem;
}

#maincard {
    padding-top: 0px;
}

h5 {
    padding-left: 1.6rem;
    padding-bottom: 0.6rem;
}

.p-badge{border-radius:2px;padding:.25em .5rem;font-weight:700;font-size:12px;text-transform: uppercase;letter-spacing:0px;line-height: unset;height: unset;}
.p-badge.visit-status-K{background-color:#b3e5fc;color:#23547b}
.p-badge.visit-status-J{background-color:#feedaf;color:#8a5340}
.p-badge.visit-status-T{background-color:#f3fff3;color:#47944c}
.p-badge.visit-status-A{background-color:#a5a2a2;color:#eeeeee}
.p-badge.visit-status-X{background-color:#ffcdd2;color:#c63737}
.p-badge.fmp{background-color:#c8e6c9;color:#256029;margin-top:.2rem;}

@media screen and (max-width: 960px) {
	::deep() .p-datatable {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}

	::deep() .p-visitbar {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
    }
    
}
</style>
