import ApiService from './api.service'

export default class VisitService {

	async getVisits() {
        const response = await ApiService.get('api/visits')
        return response.data.data
    }

    async getVisitRequests(data) {
        const response = await ApiService.get('api/visits/requests', data)
        return response.data
    }

    async setVisitRequestStatus(data) {
        const response = await ApiService.post('api/visits/requests/status', data)
        return response.data
    }

	async newVisit(visit) {
        const response = await ApiService.post('api/visits', visit)
        return response.data.id
    }

	async updateVisit(visit) {
        const response = await ApiService.put('api/visits/'+visit.id, visit)
        return response.data.id
    }

	async deleteVisit(id) {
        const response = await ApiService.delete('api/visits/'+id)
        return response
    }

	async uploadVisitFile(visitid, file) {
        let formData = new FormData()
        formData.append('visitid', visitid)
        formData.append('file', file)
        const response = await ApiService.post('api/visits/upload', formData)
        return response.data
    }

	async downloadVisitFile(fileId) {
        const requestData = {
            method: 'get',
            url: "/api/visits/download/",
            params: {
                id: fileId
            },
            responseType: 'blob',
        }
        const response = await ApiService.customRequest(requestData)
        return response
    }

}
